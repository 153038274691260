import React, { useState , useEffect } from 'react'
import './About.scss'
import { motion } from 'framer-motion'
//import { images } from '../../constants';
import { urlFor, client } from '../../client'; 
import { AppWrap, MotionWrap } from '../../wrapper';


// const abouts =[
//   { title: "Web Development", description : "We are good web developers", imgUrl : images.about01},
//   { title: "Web Design", description : "We like pretty things", imgUrl : images.about02},
//   { title: "Data visualisation", description : "We make your data look beautiful", imgUrl : images.about03},
//   { title: "Engineering", description : "Control systems, calculators", imgUrl : images.about04},

// ];
const About = () => {
  const [abouts, setAbouts] = useState([]);

  useEffect(() => {
    const query = `*[_type == "abouts"]`;
    client.fetch(query).then(data => {setAbouts(data)})
  }, []);
  

  return (
    <>
      <h2 className="head-text"> This is only part of
      <br/>
      <span> What We Do: </span>
      </h2>
      <div className="app__profiles">
        {abouts.map((about, index) => (
            <motion.div 
              whileInView={{opacity:1}}
              whileHover={{scale:1.1}}
              transition={{duration:0.5,type:"tween"}}
              className="app__profile-item"
              key={about.title + index}>

              <img src={urlFor(about.imgUrl)} alt={about.title}/>
              <h2 className='bold-text' style={{marginTop : 20}}>{about.title}</h2>
              <h2 className='p-text' style={{marginTop : 10}}>{about.description}</h2>
            </motion.div>
          ))
        }
      </div>
    </>
  )
}

export default AppWrap(
  MotionWrap(About,'app__about'),
  'about',
  'app__whitebg'
);