import React from 'react'
import { BsGithub, BsInstagram } from 'react-icons/bs'
import { FaFacebook, FaLinkedin, FaWhatsapp} from 'react-icons/fa'
const SocialMedia = () => {
return (
    <div className='app__social'>
        <a href="https://wa.me/+27682256748" target="_blank" rel="noreferrer">
            <div>
                <FaWhatsapp />
            </div>
        </a>
        <a href="https://github.com/Topo-Software" target="_blank" rel="noreferrer">
            <div>
                <BsGithub />
            </div>
        </a>
        <a href="https://www.instagram.com/toposoftware/" target="_blank" rel="noreferrer">
            <div>
                <BsInstagram />
            </div>
        </a>
        <a href="https://www.facebook.com/toposoftware/" target="_blank" rel="noreferrer">
            <div>
                <FaFacebook />
            </div>
        </a>
        <a href="https://www.linkedin.com/company/topo-software/" target="_blank" rel="noreferrer">
            <div>
                <FaLinkedin />
            </div>
        </a>
    </div>
)
}

export default SocialMedia