import React from "react";
import { navSections } from "./Navbar/Navbar";

const NavigationDots = ({ active }) => (
    <div className="app__navigation">
    {navSections.map((item, index) => (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a 
            href={`#${item}`} 
            key={item+index}
            className='app__navigation-dot'
            style={ active === item ? {backgroundColor: '#313BAC'} : {}}
            />
    ))}
    </div>
);

export default NavigationDots;
