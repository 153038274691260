import React, {useState} from 'react'
import {images} from '../../constants'
import {AppWrap,MotionWrap} from '../../wrapper'
// import {client} from '../../client' deprecated
import './Footer.scss'

const Footer = () => {
  const [formData, setFormData] = useState({ username: '', email: '', message: '' })
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)
  
  const {username, email, message} = formData;

  const handleChangeInput = (e) => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value})
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
  
    fetch('https://toposoftware.co.za/api/contactFormHandler.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        _type: 'contact',
        name: formData.username,
        email: formData.email,
        message: formData.message
      }),
    })
      .then(response => response.json())
      .then(data => {
        setLoading(false);
        setIsFormSubmitted(true);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  
  return (
    <>
      <h2 className='head-text'> Send us a WhatsApp message!</h2>
      <div className='app__footer-about-us'>
        <br/>
        <p>Topo Software is a small software development company based in Pretoria, South Africa. We specialise in software development, web design, business automation and digitisation.</p>
        <br/>
        <p>I'm Gerrit, the founder of <span>Topo Software</span>. I decided to follow my hobby of developing software and helping businesses achieve their goals. I'm always available via WhatsApp at the contact number below. Send me a WhatsApp then we'll set up a coffee.</p>
        <br/>
        <p>Moenie skaam wees nie, ons praat Afrikaans ook!</p>
      </div>
      <div className='app__footer-cards'>
        <div className='app__footer-card'>
          <img src={images.location} alt='location'/>
          <a href='https://www.google.com/maps/place/Topo+Software/@-25.7752288,28.2535587,17z/data=!3m1!4b1!4m5!3m4!1s0x82789dde34dd940d:0xd642dcd5238a8221!8m2!3d-25.7752336!4d28.2557474' className='p-text'>11 Hazelwood Rd, Pretoria, 0081</a>
        </div>
        <div className='app__footer-card'>
          <img src={images.email} alt='email'/>
          <a href='mailto:gerrit@toposoftware.co.za'className='p-text'>gerrit@toposoftware.co.za</a>
        </div>
        <div className='app__footer-card'>
          <img src={images.mobile} alt='cell'/>
          <a href='tel: +27 (0)68 225 6748'className='p-text'>+27 (0)68 225 6748</a>
        </div>
      </div>
    {!isFormSubmitted ?
      <div className='app__footer-form app__flex'>
        <div className='app__flex'>
          <input className='p-text' type='text' placeholder='Your Name' name="username" value={username} onChange={handleChangeInput}/>
        </div>
        <div className='app__flex'>
          <input className='p-text' type='text' placeholder='Your Email' name="email" value={email} onChange={handleChangeInput}/>
        </div>
        <div>
          <textarea className='p-text' type='text' placeholder='Your Message' name="message" value={message} onChange={handleChangeInput}/>
        </div>
        <button type='button' className='p-text' onClick={handleSubmit}>{loading ? 'Sending':'Send'}</button>
      </div>
      : <div className='head-text'>
        <h3 className='head-text'>Thank you for your message</h3>
      </div>
      }

    </>
  )
}

export default AppWrap(
  MotionWrap(Footer,'app__footer'),
  'contact',
  'app__whitebg')