import React from 'react'
import { motion } from 'framer-motion'
import { images  } from '../../constants'
import './Header.scss'
import {IPhone} from '../../components'

import {AppWrap} from '../../wrapper'



const scaleVariants ={
  whileInView: {
    scale: [0,1],
    opacity: [0,1],
    transition:{
      duration: 1,
      ease: 'easeInOut'
    }
    
  }
}

const Header = () => {
  return (
    <div className='app__header app__flex'>
    <motion.div
      whileInView={{x:[-100, 0], opacity: [0,1] }}
      transition = {{duration : 1}}
      className= "app__header-info"
      >

      <div className='app__header-badge'>
        <div className='badge-cmp app__flex'>
          {/* <span>👋</span> */}
          <div style={{marginLeft: 0}}>
            {/* <p className='p-text'>Hi there, we are</p> */}
            <h1 className='head-text'>Topo Software:</h1>
            <p className='p-text'>Efficiency Through Technology</p>
          </div>
        </div>

        <div className='tag-cmp app__flex'>
        <p className='p-text'>Software developers</p>
        <p className='p-text'>with engineering skills!</p>
        </div>
      </div>
    
    </motion.div>

      <motion.div
        whileInView={{opacity: [0,1] }}
        transition = {{duration : 0.5,delayChildren: 0.5}}
        className= "app__header-img">
        {/* <img src={images.profile} alt='profile_bg'/> */}
        {/* <IPhone/> */}
        <motion.div>
        <img 
          src={images.profile} 
          alt='topo icon'
          className = 'topo_icon'/>
        <motion.img
          whileInView={{scale: [0,1] }}
          transition = {{duration : 1, ease: 'easeInOut'}}
          src={images.circle}
          alt='profile_circle'
          className = 'overlay_circle'
        />
        </motion.div>
      </motion.div>

      <motion.div
        variant={scaleVariants}
        whileInView= {scaleVariants.whileInView}
        className= "app__header-circles">

        {[images.python, images.react, images.javascript].map((circle, index) => {
          return (
            <div className='circle-cmp app__flex' key={`circle-${index}`} >
            <img src={circle} alt='circle'/>
            </div>
            )
          })
        }
      
      </motion.div>

    </div>
  )
}

export default AppWrap(Header,'home')